


























import {Component, Watch} from 'vue-property-decorator';
import Base from '@/views/Base';
import ListWithHeaderLayout from '@/layouts/nested/ListWithHeaderLayout.vue';
import HistoricalService from '@/service/HistoricalService';
import BookingService from '@/service/BookingService';
import {BookingListItem} from '@/models/Booking';
import {EventBus, Events} from '@/EventBus';
import SelectRegDateModal from '@/components/modals/SelectRegDateModal.vue';
import PlaybackService from '@/service/PlaybackService';
import {Playback} from '@/models/Playback';
import SelectSurveyModal from '@/components/modals/SelectSurveyModal.vue';
import {SelectOption} from '@/models/common';
import {months} from '@/utils/date';
import {HistoricalMedia} from "@/models/api";

@Component({
  components: {
    SelectSurveyModal,
    SelectRegDateModal,
    ListWithHeaderLayout
  }
})
export default class ConferencePast extends Base {
  items: BookingListItem[] = [];

  fields = [
    {
      key: 'topic',
      label: this.t('common.topic'),
      sortable: true,
      sortDirection: 'desc',
      class: 'pl-34 truncate'
    },
    {
      key: 'date',
      label: this.t('common.start'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '145px'}
    },
    {
      key: 'duration',
      label: this.t('common.duration'),
      sortable: true,
      thStyle: {width: '100px'},
      title: this.t('common.duration') + ' (MM:SS)'
    },
    {
      key: 'playback',
      label: this.t('common.type'),
      thStyle: {width: '80px'}
    },
    {
      key: 'participants',
      label: this.t('conference.participantShort'),
      sortable: true,
      thStyle: {width: '70px'},
      title: this.t('conference.participant')
    },
    {
      key: 'costCenter',
      label: this.t('conference.costCenterShort'),
      sortable: true,
      thStyle: {width: '130px'},
      title: this.t('conference.costCenter')
    },
    {
      key: 'pin',
      label: this.t('conference.pinShort'),
      thStyle: {width: '130px'},
      title: this.t('conference.pinLong')
    },
    {
      key: 'forms',
      label: this.t('nav.forms'),
      thStyle: {width: '80px'},
      title: this.t('nav.forms')
    },
    {
      key: 'functions',
      label: this.t('conference.functions'),
      thStyle: {width: '90px'}
    },
    {
      key: 'actions',
      label: this.t('common.settings'),
      thStyle: {width: '40px'},
      thClass: 'show-content-md'
    }
  ];

  options = {
    mode: 'cards',
    entryCount: 10,
    searchTerm: '',
    currentPage: 1,
    totalRows: 0,
    sortBy: 'start',
    sortDesc: true
  };

  get dateOptions(): SelectOption[] {
    const now = new Date();
    const month = now.getMonth();
    const year = now.getFullYear();
    const options = [];
    const m = months(this);
    for (let i = month - 6; i <= month; i++) {
      const newMonth = (i + 12) % 12;
      const newYear = i < 0 ? year - 1 : year;
      options.push({
        text: `${m[newMonth]} ${newYear}`,
        value: newYear + '-' + ('0' + (newMonth + 1)).slice(-2)
      });
    }
    return [{text: 'Alle', value: ''}, ...options.reverse()];
  }

  selectedDate = ``;
  minDate = '';
  maxDate = '';

  toEditForm: BookingListItem | null = null;

  playbacks: Playback[] = [];

  mounted(): void {
    this.init();

    EventBus.$on(Events.RepeatConference, (item: BookingListItem) => this.$router.push(`/conference/booking?copyId=${item?.id}&step=0`));

    EventBus.$on(Events.ConferenceDetail, (booking: BookingListItem) => {
      switch (booking.type) {
        case 'room':
        case 'scheduled':
          this.$router.push('/conference/scheduled/' + booking.id);
          break;
        case 'past':
          this.$router.push('/conference/past/' + booking.id);
          break;
        case 'running':
          this.$router.push('/conference/running/' + booking.id);
          break;
      }
    });

    EventBus.$on(Events.EditConferenceForm, (item: BookingListItem) => {
      this.toEditForm = item;
      setTimeout(() => this.$bvModal.show('select-reg-date-modal'));
    });

    EventBus.$on(Events.EditConferenceSurvey, (item: BookingListItem) => {
      this.toEditForm = item;
      setTimeout(() => this.$bvModal.show('select-survey-modal'));
    });
  }

  beforeDestroy(): void {
    EventBus.$off(Events.RepeatConference);
    EventBus.$off(Events.ConferenceDetail);
    EventBus.$off(Events.EditConferenceForm);
    EventBus.$off(Events.EditConferenceSurvey);
  }

  @Watch('options.currentPage')
  pageChanged(): void {
    this.getHistoricals(this.options.currentPage - 1, this.options.entryCount);
  }

  @Watch('options.searchTerm')
  searchChanged(): void {
    setTimeout(() => this.getHistoricals(this.options.currentPage - 1, this.options.entryCount), 500);
  }

  @Watch('options.sortBy')
  sortByChanged(): void {
    setTimeout(() => {
      if (this.options.sortBy)
        this.getHistoricals(this.options.currentPage - 1, this.options.entryCount);
      }, 200);
  }

  @Watch('options.sortDesc')
  sortDescChanged(): void {
    setTimeout(() => {
      if (this.options.sortBy)
        this.getHistoricals(this.options.currentPage - 1, this.options.entryCount);
    }, 200);
  }

  @Watch('options.entryCount')
  entryCountChanged(): void {
    setTimeout(() => this.getHistoricals(this.options.currentPage - 1, this.options.entryCount), 200);
  }

  @Watch('selectedDate', {immediate: false})
  selectedDateChanged(): void {
    if (!this.selectedDate || this.selectedDate === '') {
      this.minDate = '';
      this.maxDate = '';
    } else {
      const [year, month] = this.selectedDate.split('-');
      const lastDay = new Date(+year, +month, 0).getDate();
      this.minDate = `${this.selectedDate}-01`;
      this.maxDate = `${this.selectedDate}-${lastDay}`;
    }
    this.getHistoricals(this.options.currentPage - 1, this.options.entryCount);
  }

  private init(): void {
    PlaybackService.getPlaybacks().then((p) => {
      this.playbacks = p;
      this.getHistoricals(this.options.currentPage - 1, this.options.entryCount);
    });
  }

  private getHistoricals(currentPage: number, perPage: number) {
    const sortDirection = this.options.sortDesc ? 'DESC' : 'ASC';
    const sortBy = this.sortBy();
    if (this.options.searchTerm !== '') currentPage = 0;
    HistoricalService.getHistoricals(
      currentPage,
      perPage,
      sortBy,
      sortDirection,
      this.options.searchTerm,
      !(sortBy === 'start' || sortBy === 'numberOfParticipants' || sortBy === 'duration'),
      this.minDate && this.maxDate
        ? {minDate: this.minDate, maxDate: this.maxDate}
        : undefined
    )
      .then(res => {
        this.toEditForm = null;
        this.options.totalRows = res.totalSize;
        this.items = res.content
          ? res.content.map(historical => BookingService.historicalToConferenceItem(historical, this.playbacks))
          : [];
        if (this.items.length === 0) return;
        HistoricalService.getMediaListForIds(this.items.map(e => e.id as string))
          .then(res => {
            this.items = this.items.map(item => {
              const list = res[item.id];
              console.log(list);
              if (list == undefined) return item;
              if (list.length > 0 && list[0].filename.includes('mp3') && item.id) {
                item.file = list[0] as HistoricalMedia;
                item.fileUrl = HistoricalService.getMediaFileUrl(item.id, item.file.filename);
              }
              return item;
            })
          })
      })
      .catch(this.showNetworkError);
  }

  private sortBy(): string {
    if (this.options.sortBy === 'date') return 'start';
    if (this.options.sortBy === 'participants') return 'numberOfParticipants';
    if (this.options.sortBy === 'recording') return 'recordingMode';
    return this.options.sortBy;
  }

}
