/* eslint-disable no-mixed-spaces-and-tabs */
import axios from 'axios';
import {Booking, Email, Historical, NationalNumber, RecordingMode, Schedule, ScheduleType} from '@/models/api';
import {BookingListItem, PinCheck} from '@/models/Booking';
import {BookingForm, BookingParticipantForm, Periodicity, ScheduleForm} from '@/models/BookingForm';
import {WizardStep} from '@/models/Wizard';
import {Account} from '@/models/Account';
import {secureRandom} from '@/utils/secureRandom';
import {durationStr} from '@/utils/filters';
import {Playback} from '@/models/Playback';
import Base from "@/views/Base";

/**
 * API calls for booking
 * @module service/BookingService
 */
export default {
	/**
	 * Rest Functions bookings
	 */
	getBookings(): Promise<Array<Booking>> {
		return axios.get('/booking').then((response) => {
			return (response.data as Booking[])
				.map((booking) => {
					if (booking.audioId && booking.audioId > 0 && booking.participants)
						booking.participants = booking.participants.filter(p => p.name !== 'Playback-Moderator');
					return booking;
				})
				.reverse();
		});
	},
	getBooking(id: string): Promise<Booking> {
		return axios.get('/booking/' + id).then((response) => {
			const booking = response.data as Booking;
			if (booking.audioId && booking.audioId > 0 && booking.participants)
				booking.participants = booking.participants.filter(p => p.name !== 'Playback-Moderator');
			return booking;
		});
	},

	createBooking(booking: Booking): Promise<Array<string>> {
		return axios.post('/booking', booking).then((response) => response.data);
	},
	testBooking(booking: Booking): Promise<PinCheck> {
		return axios
			.post('/booking/pinCheck', booking)
			.then((response) => response.data);
	},

	deleteBooking(id: string): Promise<Booking> {
		return axios.delete(`/booking/${id}`).then((response) => response.data);
	},

	deleteSeries(id: string): Promise<Booking> {
		return axios
			.delete(`/booking/series/${id}`)
			.then((response) => response.data);
	},

	sendEmailToParticipants(id: string, mail: Email): Promise<void> {
		return axios.post(`/booking/${id}/participants/mail`, mail).then();
	},

	sendTestEmailToParticipants(id: string, mail: Email): Promise<void> {
		return axios.post(`/booking/${id}/participants/testMail`, mail).then();
	},

	updateBooking(booking: Booking): Promise<string[]> {
		return axios
			.put(`/booking/${booking.id}`, booking)
			.then((response) => response.data);
	},

	datesFromSeries(series: Schedule): Promise<number[]> {
		return axios
			.post(`/booking/datesFromSeries`, series)
			.then((response) => response.data);
	},

	getNationalNumbers(): Promise<NationalNumber[]> {
		return axios.get(`/phonenumber`).then((res) => res.data);
	},
	getNationalNumbersConfig(): Promise<{
		[key: string]: string | number | boolean;
	}> {
		return axios.get(`/phonenumber/config`).then((res) => res.data);
	},

	updateNationalNumber(
		number: NationalNumber,
		newData: { active?: boolean; description?: string; displayNumber?: string }
	): Promise<NationalNumber> {
		return axios
			.put(`/phonenumber`, { number: number.number, ...newData })
			.then((res) => res.data);
	},

	moveNationalNumber(
		number: NationalNumber,
		direction: 'UP' | 'DOWN'
	): Promise<NationalNumber> {
		return axios
			.post(`/phonenumber/move`, { number: number.number, direction })
			.then((res) => res.data);
	},

	/**
	 * App Functions
	 */
	generatePin(): string {
		return Math.floor(100000 + secureRandom() * 900000).toString();
	},

	bookingToConferenceItem(
		booking: Booking,
		playbacks?: Playback[]
	): BookingListItem {
		const schedule = booking.schedule;
		let audioName;
		if (booking.audioId && booking.audioId > 0 && playbacks) {
			audioName = playbacks.find((p) => p.id === booking.audioId)?.name;
		}
		return {
			id: booking.id || '',
			date: new Date(booking.start || ''),
			topic: booking.topic || 'Kein Thema ausgewählt',
			playback: !!booking.audioId && booking.audioId > 0,
			type:
				booking.schedule?.type === ScheduleType.ANYTIME ? 'room' : 'scheduled',
			participants: booking.participants?.length || 0,
			functions: {
				dialIn: booking.dialIn,
				dialOut: booking.dialOut,
				microphone: booking.recordingMode === RecordingMode.AUTOMATIC
			},
			costCenter: booking.costCenter || '',
			pin: {
				moderator: booking.moderatorAccessCode || '0',
				participant: booking.participantAccessCode || '0'
			},
			schedule,
			registrationForm: booking.regDate,
			survey: booking.survey,
			audioName,
			duration:
				booking.end && booking.start
					? durationStr((booking.end - booking.start) / 1000)
					: undefined,
			operatorBookingId: booking.operatorBooking
				? booking.operatorBooking.id
				: undefined
		};
	},

	historicalToConferenceItem(
		booking: Historical,
		playbacks?: Playback[]
	): BookingListItem {
		let audioName;
		if (booking && booking.audioId && booking.audioId > 0 && playbacks) {
			audioName = playbacks.find((p) => p.id === booking.audioId)?.name;
		}
		return {
			id: booking?.id || '',
			date: new Date(booking.start || ''),
			topic: booking.topic || 'Kein Thema ausgewählt',
			playback: !!booking.audioId && booking.audioId > 0,
			type: 'past',
			participants: booking.numberOfParticipants,
			functions: {
				dialIn: booking.dialIn,
				dialOut: booking.dialOut,
				microphone: booking.recordingMode === RecordingMode.AUTOMATIC
			},
			bookingId: booking.bookingId,
			costCenter: booking.costCenter || '',
			pin: {
				moderator: booking.moderatorAccessCode || '000000',
				participant: booking.participantAccessCode || '000000'
			},
			duration: durationStr(+booking.duration),
			registrationForm: booking.regDate,
			survey: booking.survey,
			file: null,
			fileUrl: null,
			audioName
		};
	},

	getWizardSteps(
		dialout: boolean,
		dialin: boolean,
		participantsCount: string,
		instance: Base
	): WizardStep[] {
		return [
			{
				id: 'date',
				title: instance.t('common.appointmentDate'),
				valid: true,
				visited: false,
				tag: instance.t('conference.immediate'),
				touched: false,
				dirty: false
			},
			{
				id: 'dialOut',
				title: instance.t('common.dialOut'),
				valid: true,
				visited: false,
				disabled: !dialout,
				tag: participantsCount,
				dirty: false
			},
			{
				id: 'dialin',
				title: instance.t('common.dialInEn'),
				valid: true,
				visited: false,
				disabled: false,
				tag: dialin ? instance.t('common.activated') : instance.t('common.deactivated'),
				dirty: false
			},
			{
				id: 'options',
				title: instance.t('common.options'),
				valid: true,
				visited: false,
				tag: '',
				dirty: false
			}
		];
	},

	bookingFormToBooking(
		form: BookingForm,
		dialOut: boolean,
		dialIn: boolean
	): Booking {
		const participants = !dialOut
			? []
			: form.dialOut.participants.map((p) => ({
					name: p.name,
					phone: p.phone,
					email: p.email,
					company: p.company,
					moderator: p.admin
			  }));
		return {
			topic: form.date.topic,
			moderatorAccessCode: !dialIn
				? undefined
				: form.dialIn.moderatorAccessCode,
			participantAccessCode: !dialIn
				? undefined
				: form.dialIn.participantAccessCode,
			costCenter: form.date.costCenter.split('|')[0],
			language: form.date.language,
			participants,
			schedule: this.scheduleFormToSchedule(form.date.schedule),
			recordingMode: form.options.recordingMode
				? RecordingMode.AUTOMATIC
				: RecordingMode.NONE,
			waitingRoom: form.options.waitingRoom,
			beep: form.options.beep,
			dialOutCalleeConfirm: form.options.dialOutCalleeConfirm,
			autoClose: form.options.endConference,
			joinMuted: form.options.schooling,
			dialIn: dialIn,
			dialOut: dialOut,
			audioId: form.date.type === 'LIVE' ? -1 : form.date.playbackId
		};
	},

	newBookingForm(user: Account): BookingForm {
		console.log('Get a new blank booking form')
		const date = new Date();
		date.setHours((date.getHours() + 3) % 24);
		date.setMinutes(0);
		return {
			date: {
				topic: '',
				language: navigator.language.includes('en') ? 'EN' : 'DE',
				costCenter: user?.costCenter || '',
				schedule: {
					type: ScheduleType.IMMEDIATE,
					start: date,
					duration: 1,
					isSeries: false,
					allowedWeekdays: [false, false, false, false, false, true, true],
					timeInterval: 1,
					ordinalDayOfMonth: -1,
					end: new Date(Date.now() + 1000 * 60 * 60 * 24 * 2),
					periodicity: 'DAILY',
					periodicityStr: 'DAILY'
				},
				type: 'LIVE',
				playbackId: undefined
			},
			dialIn: {
				moderatorAccessCode: this.generatePin(),
				participantAccessCode: this.generatePin()
			},
			dialOut: {
				participants: user ? [this.createDefaultUser(user)] : []
			},
			options: {
				schooling: false,
				waitingRoom: false,
				endConference: false,
				recordingMode: false,
				beep: false,
				dialOutCalleeConfirm: false
			}
		};
	},

	createDefaultUser(user: Account): BookingParticipantForm {
		return {
			id: '0',
			number: 0,
			name: user.contact.firstName + ' ' + user.contact.lastName,
			phone: user.contact.phone,
			company: user.contact.company || '',
			admin: true,
			email: user.contact.email || ''
		};
	},

	isSeries(scheduleType: ScheduleType): boolean {
		return (
			scheduleType === ScheduleType.DAILY ||
			scheduleType === ScheduleType.MONTHLY ||
			scheduleType === ScheduleType.WEEKLY
		);
	},

	isRecurrence(scheduleType: ScheduleType): boolean {
		return (
			scheduleType === ScheduleType.ONETIME ||
			scheduleType === ScheduleType.DAILY ||
			scheduleType === ScheduleType.MONTHLY ||
			scheduleType === ScheduleType.WEEKLY
		);
	},

	async getStartDate(isSeries: boolean, booking: Booking, schedule?: Schedule): Promise<Date> {
		if (isSeries && schedule) {
			const dates = await this.datesFromSeries(schedule);
			return new Date(dates.find(date => date > Date.now()) || 0);
		}
		return booking.start ? new Date(booking.start) : new Date();
	},

	async bookingToBookingForm(booking: Booking, user: Account): Promise<BookingForm> {
		const schedule = booking.schedule;
		const costCenter = !booking.costCenter
			? ' | ' + user.costCenter
			: booking.costCenter + (
			user.costCenter
				? ' | ' + user.costCenter.split('|').filter((cs) => cs !== booking.costCenter).join('|')
				: ''
		);
		const isSeries = schedule ? this.isSeries(schedule.type) : false;
		const allowedWeekdays = [
			schedule?.mon || false,
			schedule?.tue || false,
			schedule?.wed || false,
			schedule?.thu || false,
			schedule?.fri || false,
			schedule?.sat || false,
			schedule?.sun || false
		];
		let periodicity: Periodicity = 'DAILY';
		let periodicityStr = 'DAILY';
		if (schedule && isSeries) {
			switch (schedule.type) {
				case ScheduleType.DAILY: {
					periodicity = 'DAILY';
					periodicityStr = 'DAILY';
					break;
				}
				case ScheduleType.MONTHLY: {
					periodicity = 'MONTHLY';
					periodicityStr = 'MONTHLY';
					break;
				}
				case ScheduleType.WEEKLY: {
					periodicity = 'WEEKLY';
					periodicityStr = 'WEEKLY,' + schedule.timeInterval;
					break;
				}
			}
		}

		return {
			date: {
				topic: booking.topic || '',
				language: booking.language
					? booking.language.toUpperCase()
					: navigator.language.includes('en')
						? 'EN'
						: 'DE',
				costCenter: costCenter,
				schedule: {
					type: booking.schedule?.type ? ScheduleType.ONETIME : ScheduleType.IMMEDIATE,
					start: await this.getStartDate(isSeries, booking, schedule),
					duration: 1,
					isSeries,
					allowedWeekdays,
					timeInterval: booking.schedule?.timeInterval || 1,
					ordinalDayOfMonth: booking.schedule?.ordinalDayOfMonth || 1,
					end: new Date(
						booking.schedule?.end || Date.now() + 1000 * 60 * 60 * 24 * 2
					),
					periodicity,
					periodicityStr
				},
				type: booking.audioId && booking.audioId > 0 ? 'PLAYBACK' : 'LIVE',
				playbackId: booking.audioId
			},
			dialIn: {
				moderatorAccessCode: booking.moderatorAccessCode || this.generatePin(),
				participantAccessCode:
					booking.participantAccessCode || this.generatePin()
			},
			dialOut: {
				participants: booking.participants
					? booking.participants
						.filter(p => p.name !== 'Playback-Moderator')
						.map((p, i) => ({
							admin: p.moderator,
							email: p.email || '',
							phone: p.phone,
							name: p.name,
							company: p.company || '',
							id: i.toString(),
							number: i
						}))
					: [{
						id: '0',
						number: 0,
						name: user.contact.firstName + ' ' + user.contact.lastName,
						phone: user.contact.phone,
						admin: true,
						company: user.contact.company || '',
						email: user.contact.email || ''
					}]
			},
			options: {
				schooling: booking.joinMuted || false,
				waitingRoom: booking.waitingRoom || false,
				endConference: booking.autoClose || false,
				recordingMode:
					booking.recordingMode === RecordingMode.AUTOMATIC || false,
				beep: booking.beep || false,
				dialOutCalleeConfirm: booking.dialOutCalleeConfirm || false
			}
		};
	},

	scheduleFormToSchedule(schedule: ScheduleForm): Schedule {
		let type: ScheduleType = schedule.type;
		if (schedule.type === ScheduleType.ONETIME && schedule.isSeries) {
			switch (schedule.periodicity) {
				case 'DAILY':
					type = ScheduleType.DAILY;
					break;
				case 'WEEKLY':
					type = ScheduleType.WEEKLY;
					break;
				case 'MONTHLY':
					type = ScheduleType.MONTHLY;
					break;
			}
		}

		return {
			start: schedule.start.getTime(),
			end: schedule.end.getTime(),
			mon: schedule.allowedWeekdays[0],
			tue: schedule.allowedWeekdays[1],
			wed: schedule.allowedWeekdays[2],
			thu: schedule.allowedWeekdays[3],
			fri: schedule.allowedWeekdays[4],
			sat: schedule.allowedWeekdays[5],
			sun: schedule.allowedWeekdays[6],
			type,
			ordinalDayOfMonth: schedule.ordinalDayOfMonth,
			timeInterval: schedule.timeInterval
		};
	},

	sortPastParticipants(a: any, b: any): number {
		if (b.duration === '-' && a.duration !== '-') return -1;
		if (b.duration === '-' && a.duration === '-') return 0;
		if (a.duration === '-' && b.duration !== '-') return 1;
		if (a.start === b.start) {
			if (a.idx === undefined && b.idx !== undefined) return 1;
			if (a.idx !== undefined && b.idx === undefined) return -1;
			return a.idx < b.idx ? -1 : 1;
		}
		return a.start < b.start ? -1 : 1;
	}
};
