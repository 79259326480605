import Vue from "vue";

export function months(instance: Vue): any[] {
	return [
		instance.$t('months.jan'),
		instance.$t('months.feb'),
		instance.$t('months.mar'),
		instance.$t('months.apr'),
		instance.$t('months.may'),
		instance.$t('months.jun'),
		instance.$t('months.jul'),
		instance.$t('months.aug'),
		instance.$t('months.sep'),
		instance.$t('months.oct'),
		instance.$t('months.nov'),
		instance.$t('months.dec')
	];
}

export function days(instance: Vue): any[] {
	return [
		instance.$t('days.mon'),
		instance.$t('days.tue'),
		instance.$t('days.wed'),
		instance.$t('days.thu'),
		instance.$t('days.fri'),
		instance.$t('days.sat'),
		instance.$t('days.sun')
	];
}
