





































import Base from "@/views/Base";
import {Component, Prop, Watch} from "vue-property-decorator";
import WSwitch from "@/components/WSwitch.vue";
import {
  Element,
  ElementListItem,
  EventsHandler,
  PresentationDetailsObject,
  PresentationOptions,
  SurveyElement
} from '@/models/Presentation';
import pdf from '@teckel/vue-pdf'
import axios from "axios";
import PresentationService from "@/service/PresentationService";
import SurveyPreview from "@/views/presentation/components/SurveyPreview.vue";

@Component({
  components: {SurveyPreview, WSwitch, pdf}
})
export default class ElementPlayerModal extends Base {
  @Prop({type: Number, required: true}) presentationId!: number;
  @Prop() items!: ElementListItem[];
  @Prop() selectedElementIdx!: number;

  iFrameWindow: Window | null = null;
  iFrameDocument: Document | null = null;
  iFrameContainer: HTMLElement | null = null;
  totalSteps = 0;
  currentSlideEnded = false;
  visible = false;
  presentationDetails: PresentationDetailsObject | null = null;

  init() {
    if (this.isPptx) this.initPptxIFrame();
  }

  mounted() {
    this.iFrameContainer = document.getElementById('iframe-container');
  }

  initPptxIFrame() {
    if (!this.iFrameContainer) return;
    while (this.iFrameContainer.firstChild) this.iFrameContainer.removeChild(this.iFrameContainer.firstChild);

    console.debug('Initializing PPTX iframe');
    const iframe = document.createElement('iframe');
    iframe.id = 'pptx-iframe';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';

    iframe.onload = () => {
      console.debug('Iframe loaded');
      this.iFrameDocument = iframe.contentDocument;
      console.debug('Document:', this.iFrameDocument);
      if (!this.iFrameDocument) return;

      this.appendStylesElement(this.iFrameDocument);

      const contentHolder = this.iFrameDocument.createElement('div');
      contentHolder.id = 'contentHolder';
      this.iFrameDocument.body.appendChild(contentHolder);
      this.iFrameDocument.body.style.margin = '0px';

      const resizer = this.iFrameDocument.createElement('div');
      resizer.id = 'resizer';
      contentHolder.appendChild(resizer);

      const script = this.iFrameDocument.createElement('script');
      script.src = this.scriptUrl;
      script.type = 'text/javascript';
      script.onload = this.createPptxPlayer;
      script.onerror = () => console.error('Script failed to load');

      this.iFrameDocument.body.appendChild(script);
      this.iFrameWindow = iframe.contentWindow;

      if (this.iFrameWindow) this.iFrameWindow.addEventListener('resize', () => this.resizePptxPlayer());
      else console.error('iFrameWindow not found');

      console.log('Script appended');
    };

    this.iFrameContainer.appendChild(iframe);
  }

  resizePptxPlayer() {
    const contentHolder = this.iFrameDocument?.getElementById('contentHolder');
    if (!contentHolder) return;
    const resizer = this.iFrameDocument?.getElementById('resizer');
    if (!resizer) return;

    contentHolder.style.width = `${this.iFrameContainer?.clientWidth}px`;
    contentHolder.style.height = `${this.iFrameContainer?.clientHeight}px`;

    if (this.presentationDetails && this.iFrameContainer) {
      const {Width, Height} = this.presentationDetails;
      const aspectRatio = Width / Height;
      const containerAspectRatio = this.iFrameContainer.clientWidth / this.iFrameContainer.clientHeight;

      const scale = (containerAspectRatio > aspectRatio) ?
        this.iFrameContainer.clientHeight / Height :
        this.iFrameContainer.clientWidth / Width;

      const scaledWidth = Width * scale;
      const scaledHeight = Height * scale;
      contentHolder.style.width = `${scaledWidth}px`;
      contentHolder.style.height = `${scaledHeight}px`;

      const marginLeft = (this.iFrameContainer.clientWidth - scaledWidth) / 2;
      const marginTop = (this.iFrameContainer.clientHeight - scaledHeight) / 2;

      resizer.style.transform = `scale(${scale})`;
      contentHolder.style.marginLeft = `${marginLeft}px`;
      contentHolder.style.marginTop = `${marginTop}px`;
    }
    console.debug('Resized');
  }

  createPptxPlayer() {
    console.debug('Creating PPTX player');

    const eventsHandler: EventsHandler = {
      OnLoadingStart: () => console.debug("PPTX-Player: Loading slide started"),
      OnLoadingEnd: () => console.debug("PPTX-Player: Loading slide ended"),
      OnSlideChange: (slideNo: number) => {
        console.debug("PPTX-Player: Slide changed to:", slideNo);
        this.currentSlideEnded = false;
        this.totalSteps = this.iFrameWindow?.Presentation?.GetCurrentAnimationCount(slideNo) || 0;
      },
      OnStartButtonShow: (slideNo: number) => console.debug("PPTX-Player: Start button shown on slide:", slideNo),
      OnStartButtonClose: (slideNo: number) => console.debug("PPTX-Player: Start button closed on slide:", slideNo),
      OnSlideTransitionEnd: (slideNo: number) => console.debug("PPTX-Player: Slide transition ended for slide:", slideNo),
      OnMovToNxtAnim: () => console.debug("PPTX-Player: Moved to next animation"),
      OnMovToPrvAnim: () => {
        this.currentSlideEnded = false;
        console.debug("PPTX-Player: Moved to previous animation");
      },
      OnPlayingStart: () => console.debug("PPTX-Player: Playing started"),
      OnPlayingEnd: () => console.debug("PPTX-Player: Playing ended"),
      OnProgressChange: (time: number) => ({}),
      OnSlideEnd: (slideNo: number, presEnd) => {
        this.currentSlideEnded = true;
        console.debug(`PPTX-Player: Slide ${slideNo} ended. Presentation ended: ${presEnd}`);
      },
      OnClick: (coordinates) => console.debug(`PPTX-Player: Clicked at coordinates: x=${coordinates.x}, y=${coordinates.y}`),
      OnSldOutlineLoad: (outlineObject) => console.debug("PPTX-Player: Slide outline loaded:", outlineObject),
      OnMediaPlay: (id, fileFound) => console.debug(`PPTX-Player: Media play event: id=${id}, fileFound=${fileFound}`),
      OnMediaPause: (id) => console.debug(`PPTX-Player: Media paused: id=${id}`),
      InitaliseSettings: (detailsObject) => {
        this.presentationDetails = detailsObject;
        this.resizePptxPlayer();
        console.debug("PPTX-Player: Initialised settings with details:", detailsObject);
      },
    };

    const options: PresentationOptions = {
      playTransitionOnLoad: true,
      playAnimationsOnLoad: true,
      respectHiddenSlideStatus: true,
    };

    if (!this.iFrameWindow || !this.elementListItem.slideNumber) return;
    this.iFrameWindow.Presentation = new this.iFrameWindow.DopPresentation(eventsHandler, options);

    console.log("Presentation created:", (this.iFrameWindow as any).Presentation);

    this.iFrameWindow.Presentation.SetRPath(this.pptxBaseUrl);
    this.iFrameWindow.Presentation.LoadPres(`/${this.elementListItem.pptxProcessId}`)

    this.setInitialSlide();
  }

  setInitialSlide() {
    console.log('Setting initial slide');
    try {
      this.iFrameWindow?.Presentation?.JumpToAnim(0, this.elementListItem?.slideNumber || 0, 0);
      const currentStatus = this.iFrameWindow?.Presentation?.CurrentStatus();

      if (!currentStatus || currentStatus.slide !== this.elementListItem?.slideNumber) {
        console.log('Slide not loaded yet. Trying again...');
        setTimeout(this.setInitialSlide, 50);
      }
    } catch (e) {
      console.error('Error setting initial slide:', e);
      setTimeout(this.setInitialSlide, 50)
    }

  }

  next() {
    console.debug('Next material');
    if (!this.isPptx) this.$emit('next');
    else {
      if (!this.iFrameWindow || !this.iFrameWindow.Presentation) return;
      if (this.currentSlideEnded && this.nextElement) {
        this.$emit('next');
        this.iFrameWindow.Presentation.JumpToAnim(0, this.nextElement?.slideNumber || 0, 0);
      } else {
        this.iFrameWindow.Presentation.Next();
      }
    }
  }

  prev() {
    console.debug('Previous material');
    if (!this.isPptx) this.$emit('prev');
    else {
      let currentStatus = this.iFrameWindow?.Presentation?.CurrentStatus();
      if (currentStatus?.step === 0 && this.prevElement) {
        this.$emit('prev');
        this.iFrameWindow?.Presentation?.JumpToAnim(0, this.prevElement?.slideNumber || 0, 0);
      } else {
        this.iFrameWindow?.Presentation?.Prev();
      }
    }
  }

  reset() {
    console.debug('Resetting player');
    if (!this.iFrameWindow) return;
    delete this.iFrameWindow.Presentation;
    this.iFrameWindow = null;
    this.iFrameContainer ? this.iFrameContainer.innerHTML = '' : console.error('iFrameContainer not found');
  }

  @Watch('element', {immediate: true, deep: true})
  onElementChanged(newElement: ElementListItem, oldElement: ElementListItem) {
    if (!this.visible) return;
    if (!oldElement || newElement.id === oldElement.id) return;
    if (newElement.type === 'PPTX') {
      if (newElement.pptxProcessId !== oldElement.pptxProcessId) {
        console.debug('PPTX changed. Reinitializing...');
        this.init();
      }
    }
  }

  get elementListItem(): ElementListItem {
    return this.items[this.selectedElementIdx];
  }

  get element(): Element {
    return this.elementListItem.entity;
  }

  get surveyElement(): SurveyElement {
    return this.elementListItem.entity as SurveyElement;
  }

  get nextElement(): ElementListItem | null {
    return this.items[this.selectedElementIdx + 1] || null;
  }

  get prevElement(): ElementListItem | null {
    return this.items[this.selectedElementIdx - 1] || null;
  }

  get reachedPresentationEnd(): boolean {
    if (this.nextElement) return false;
    else if (this.isPptx) return this.currentSlideEnded;
    else return true;
  }

  get imageUrl(): string {
    return PresentationService.getImageURL(this.elementListItem.id, this.presentationId);
  }

  get videoUrl(): string {
    return PresentationService.getVideoUrl(this.elementListItem.id, this.presentationId);
  }

  get isPptx(): boolean {
    return this.elementListItem.type === 'PPTX';
  }

  get isImage(): boolean {
    return this.elementListItem.type === 'IMAGE' && this.visible
  }

  get isWebVideo(): boolean {
    return this.elementListItem.type === 'WEBVIDEO' && this.visible
  }

  get isYouTube(): boolean {
    return this.element.videoSource === 'YOUTUBE' && this.visible
  }

  get isVimeo(): boolean {
    return this.element.videoSource === 'VIMEO' && this.visible
  }

  get isVideo(): boolean {
    return this.elementListItem.type === 'VIDEO' && this.visible
  }

  get youtubeUrl(): string {
    return `https://www.youtube.com/embed/${this.element.videoId}?autoplay=1` +
      (this.element.start ? `&start=${this.element.start}` : "");
  }

  get vimeoUrl(): string {
    return `https://player.vimeo.com/video/${this.element.videoId}?autoplay=1&autopause=0` +
      (this.element.hParam ? `&h=${this.element.hParam}` : "") +
      (this.element.start ? `#t=${this.element.start}` : "");
  }

  get webVideoUrl(): string {
    return this.isYouTube ? this.youtubeUrl : this.vimeoUrl;
  }

  get isSurveyForm(): boolean {
    return this.elementListItem.type === 'SURVEY_FORM' && this.visible
  }

  get isSurvey(): boolean {
    return this.elementListItem.type === 'SURVEY' && this.visible
  }

  get isPdf(): boolean {
    return this.elementListItem.type === 'PDF' && this.visible
  }

  get pptxBaseUrl(): string {
    if (!this.elementListItem || !this.elementListItem.pptxProcessId) return '';
    return axios.defaults.baseURL + `/presentation/${this.presentationId}/pptx/converted`;
  }

  get pdfUrl(): string {
    if (!this.element.pdfProcessId) return '';
    return PresentationService.getPdfUrl(this.presentationId, this.element.pdfProcessId);
  }

  get pdfPage(): number {
    return this.element.pageNumber || 0;
  }

  get pptxUrl(): string {
    return `${this.pptxBaseUrl}/${this.elementListItem.pptxProcessId}`;
  }

  get scriptUrl(): string {
    return `${this.pptxUrl}/script.js`;
  }

  get surveyFormUrl(): string {
    return (process.env.VUE_APP_SURVEY_URL ?? '') + this.element.surveyFormUrl;
  }

  appendStylesElement(doc: Document): void {
    // This CSS is needed for the PPTX player to work
    const style = doc.createElement('style');
    style.textContent = `
      .DC1 {
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        opacity: 1;
      }
      .DC0 {
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        opacity: 0;
      }
      canvas {
        top: 0px;
        left: 0px;
        position: absolute;
      }
      #contentHolder div, iframe {
        position: absolute;
      }
      pre {
        position: absolute;
        margin: 0px;
        letter-spacing: -0.04px;
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -o-transform-origin: 0 0;
      }
      #contentHolder img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
      }
      #resizer {
        top: 0px;
        overflow: hidden;
        -moz-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
      }
      #contentHolder {
        position: relative;
        width: 100%;
        height: 540px;
        overflow: hidden;
      }`;
    doc.head.appendChild(style);
  }
}
