
















































































































import { Component, Watch } from 'vue-property-decorator';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import Base from '@/views/Base';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import { CreateSpeakerCard, SpeakerCardModel } from '@/models/SpeakerCard';
import SpeakerCardService from '@/service/SpeakerCardService';
import SpeakerCard from '@/views/presentation/speakerProfiles/SpeakerCard.vue';
import { debounce } from 'lodash';
import draggable from 'vuedraggable';

@Component({
  components: { draggable, SpeakerCard, WListOptions, HeaderLayout, WHeaderBtn }
})
export default class Index extends Base {
  options = {
    searchTerm: ''
  };

  items: SpeakerCardModel[] = [];
  itemsActive: SpeakerCardModel[] = [];
  itemsInactive: SpeakerCardModel[] = [];
  dragging = false;

  @Watch('options.searchTerm')
  searchTermChanged() {
    this.debounceLoadCards();
  }

  mounted() {
    this.loadCards();
  }

  debounceLoadCards = debounce(this.loadCards, 500);

  loadCards() {
    SpeakerCardService.getSpeakerCards(this.options.searchTerm).then(res => {
      this.items = res;
      this.itemsActive = res.filter(card => card.active);
      this.itemsInactive = res.filter(card => !card.active);
    }).catch(this.showNetworkError);
  }

  getFullPhotoUrl(id: string, filename?: string): string | undefined {
    if (filename == undefined) {
      return undefined;
    }
    return SpeakerCardService.getSpeakerPhotoUrl(id, filename);
  }

  updateCard(id: string) {
    this.$router.push('/presentation/speaker-profile/' + id);
  }

  sort() {
    this.loadCards();
  }

  async deleteCard(id: string) {
    const card = this.items.find(card => card.id == id);
    if (!card) {
      return;
    }
    if (!await this.$bvModal.msgBoxConfirm(
      this.$t('presentation.speakerProfiles.deleteConfirmModal', { name: card.name }) as string,
      {
        okVariant: 'danger',
        okTitle: this.t('presentation.speakerProfiles.deleteConfirmModalOk'),
        cancelTitle: this.t('common.cancel'),
        centered: true,
        title: this.t('presentation.speakerProfiles.deleteConfirmModalTitle')
      }
    )) {
      return;
    }
    await SpeakerCardService.deleteSpeakerCard(id);
    this.toast(this.t('presentation.speakerProfiles.deletedToast'), 'success');
    this.loadCards();
  }

  async duplicateCard(id: string) {
    const card = this.items.find(card => card.id == id);
    if (!card) {
      return;
    }

    const dup = await SpeakerCardService.duplicateSpeakerCard(id);
    await this.$router.push(`/presentation/speaker-profile/${dup.id}`);
  }

  favoriteClicked(id: string) {
    console.log('favoriteClicked', id);
    const card = this.items.find(card => card.id == id);
    if (!card) {
      return;
    }

    const createCard: CreateSpeakerCard = {
      ...card,
      active: !card.active,
      profileActive: !!card.speakerProfile
    };

    SpeakerCardService.updateSpeakerCard(id, createCard).then(this.loadCards).catch(this.showNetworkError);
  }

  async updateItems() {
    this.dragging = false;
    const indexes: { [id: string]: any } = {};

    this.itemsActive.forEach((item, index) => {
      indexes[item.id] = index;
    });
    this.itemsInactive.forEach((item, index) => {
      indexes[item.id] = index;
    });
    try {
      const changedItems = this.items.filter(item => item.active != this.itemsActive.includes(item));
      // Should be only one item
      for (const item of changedItems) {
        const createCard: CreateSpeakerCard = {
          ...item,
          active: !item.active,
          profileActive: !!item.speakerProfile
        };
        await SpeakerCardService.updateSpeakerCard(item.id, createCard);
      }

      await SpeakerCardService.reorderSpeakerCards(indexes);
    } catch (e) {
      this.showNetworkError(e);
    }

    this.loadCards();
  }

}
