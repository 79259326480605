import { render, staticRenderFns } from "./SurveyPreview.vue?vue&type=template&id=8e20160e&scoped=true&"
import script from "./SurveyPreview.vue?vue&type=script&lang=ts&"
export * from "./SurveyPreview.vue?vue&type=script&lang=ts&"
import style0 from "./SurveyPreview.vue?vue&type=style&index=0&id=8e20160e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e20160e",
  null
  
)

export default component.exports