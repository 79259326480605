










import { Component, Prop, Vue } from 'vue-property-decorator';
import { DownloadListItem } from '@/models/Presentation';
import axios from 'axios';

@Component({})
export default class DownloadThumbnail extends Vue {
  @Prop({ required: true }) item!: DownloadListItem;

  POWERPOINT_CONTENT_TYPES = [
    'application/vnd.ms-powerpoint',                                                // This MIME type is for both ppt and pot files
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'pot', 'potx', 'ppt', 'pptx'

  ];
  VIDEO_CONTENT_TYPES = ['video/mp4', 'video/quicktime', 'video/x-msvideo', 'video/x-flv', 'video/x-matroska'];
  PDF_CONTENT_TYPES = ['application/pdf'];
  IMAGE_CONTENT_TYPES = ['image/jpeg', 'image/png', 'image/webp', 'image/gif', 'image/bmp'];

  get isImage(): boolean {
    return this.IMAGE_CONTENT_TYPES.includes(this.item.fileType);
  }

  get imageThumbnailUrl(): string {
    return axios.defaults.baseURL + '/presentation/' + this.item.presentationId + '/download/' + this.item.uuid + '/thumbnail';
  }

  get isPdf(): boolean {
    return this.PDF_CONTENT_TYPES.includes(this.item.fileType);
  }

  get isVideo(): boolean {
    return this.VIDEO_CONTENT_TYPES.includes(this.item.fileType);
  }

  get isPowerpoint(): boolean {
    return this.POWERPOINT_CONTENT_TYPES.includes(this.item.fileType);
  }

}
