















































































































































import Base from '@/views/Base';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {quillEditor} from 'vue-quill-editor';
import {
  CreateSurveyElement,
  ElementListItem,
  ImportProcess,
  IndexForm,
  PresentationForm,
  WebVideoElementForm
} from '@/models/Presentation';
import WSwitch from '@/components/WSwitch.vue';
import PresentationElementList from '@/views/presentation/components/ElementList.vue';
import PresentationElementTable from '@/views/presentation/components/ElementTable.vue';
import {presentationNotesEditorOptions} from '@/utils/quill';
import PresentationService from '@/service/PresentationService';
import WebVideoForm from '@/views/presentation/components/WebVideoForm.vue';
import {debounce} from 'lodash';
import FileForm from '@/views/presentation/components/FileForm.vue';
import BatchEdit from '@/views/presentation/components/BatchEdit.vue';
import WProgressBar from '@/components/WProgressBar.vue';
import SurveyForm from '@/views/presentation/components/SurveyForm.vue';
import ElementPlayerModal from '@/views/presentation/components/ElementPlayerModal.vue';


@Component({
  components: {
    ElementPlayerModal,
    SurveyForm,
    WProgressBar,
    BatchEdit,
    FileForm,
    WebVideoForm,
    PresentationElementList,
    PresentationElementTable,
    WSwitch,
    quillEditor
  }
})
export default class PresentationElements extends Base {
  @Prop() form!: PresentationForm;

  items: ElementListItem[] = [];
  selectedElementIdx = -1;   // Careful: idx starts at 0. -1 means no element is selected

  showNotes = false;
  columns: string | number = 3;
  editIds: number[] = [];
  allSelected = false;
  editorOptions = presentationNotesEditorOptions;

  currentUploads: {
    file: File,
    progress: number,
    abortController: AbortController
  }[] = [];

  importProcesses: ImportProcess[] = [];
  importTimeoutRunning = false;

  mounted() {
    this.getElements();
    this.getImportProcesses();
    window.addEventListener('keydown', this.handleKeydown);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  }

  getElements(emitUpdate = false) {
    PresentationService.getElements(this.form.id)
      .then(res => this.items = res.map(PresentationService.elementToListItem))
      .catch(this.showNetworkError);
    if (emitUpdate) this.$emit('update');
  }

  getImportProcesses() {

    PresentationService.getImportProcesses(this.form.id)
      .then(res => {
        const previousStatuses = this.importProcesses.map(p => p.status);
        this.importProcesses = res;
        if (previousStatuses.length !== this.importProcesses.length ) this.getElements(true)
        else if (previousStatuses.some((status, idx) => status !== 'FINISHED' && this.importProcesses[idx].status === 'FINISHED')) this.getElements(true);
        if (this.importProcesses.length > 0 && !this.importTimeoutRunning) {
          this.importTimeoutRunning = true;
          setTimeout(() => {
            this.importTimeoutRunning = false;
            this.getImportProcesses();
          }, 1500);
        }
      })
      .catch(this.showNetworkError);
  }

  handleKeydown(event: KeyboardEvent) {
    // let activeElement = document.activeElement;
    // if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || activeElement.getAttribute('contenteditable') === 'true')) return;
    if (!this.selectedElement) return;

    if ((event.key === 'ArrowRight' || event.key === 'ArrowDown') && this.selectedElementIdx < this.items.length - 1) {
      this.setSelectedElement(this.items[this.selectedElementIdx + 1]);
    } else if ((event.key === 'ArrowLeft' || event.key === 'ArrowUp') && this.selectedElementIdx > 0) {
      this.setSelectedElement(this.items[this.selectedElementIdx - 1]);
    }
  }

  createWebVideoElement(webVideoForm: WebVideoElementForm) {
    PresentationService.createWebVideoElement(this.form.id, webVideoForm)
      .then(res => {
        this.items.push(PresentationService.elementToListItem(res));
        this.$emit('update');
        this.toast(this.$t('presentation.webVideo.added') as string, 'success');
      })
      .catch(this.showNetworkError);
  }

  createSurveyFormElement(surveyId: number) {
    PresentationService.createSurveyFormElement(this.form.id, surveyId)
      .then(res => {
        this.items.push(PresentationService.elementToListItem(res));
        this.$emit('update');
        this.toast(this.$t('presentation.survey.added') as string, 'success');
      })
      .catch(this.showNetworkError);
  }

  createSpontaneousSurveyElement(survey: CreateSurveyElement) {
    PresentationService.createSpontaenousSurveyElement(this.form.id, survey)
      .then(res => {
        this.items.push(PresentationService.elementToListItem(res));
        this.$emit('update');
        this.toast(this.$t('presentation.survey.added') as string, 'success');
      })
      .catch(this.showNetworkError);
  }
  editSpontaneousSurveyElement(survey: CreateSurveyElement, id: number|string) {
    PresentationService.editSpontaneousSurveyElement(this.form.id, survey, id)
      .then(res => {
        const item = this.items.find(i => i.id === id);
        if(item){
          item.entity = res;
        }
        this.$emit('update');
        this.toast(this.$t('presentation.survey.added') as string, 'success');
      })
      .catch(this.showNetworkError);
  }

  rowSelected(item: ElementListItem, shiftKey = false): void {
    item.selected = !item.selected;
    if (item.selected) this.editIds.push(item.id);
    else this.editIds = this.editIds.filter(id => id !== item.id);
    if (shiftKey) this.selectBetween(item, item.selected);
  }

  @Watch('showNotes')
  onShowNotesChange(value: boolean) {
    if (value && !this.selectedElement) this.selectedElementIdx = 0;
  }

  @Watch('editIds', {deep: true})
  onEditIdsChanged() {
    this.allSelected = this.editIds.length === this.items.length && this.items.length > 0;
  }

  setSelectedElement(item: ElementListItem): void {
      this.selectedElementIdx = this.items.findIndex(i => i.id === item.id);
  }

  updateIndexes(): void {
    let indexList = this.items.map((item, idx) => ({id: item.id, idx: idx + 1}) as IndexForm);
    PresentationService
      .updateElementIndexes(this.form.id, indexList)
      .then(res => this.items = res.map(PresentationService.elementToListItem))
      .catch(this.showNetworkError);
  }

  updateElement(item?: ElementListItem): void {
    const element = item || this.selectedElement;
    if (!element) return;
    const form = PresentationService.elementListItemToForm(element, this.form.id);
    PresentationService.updateElement(form)
      // .then(() => this.toast(this.$t('presentation.elementSaved') as string, 'success'))
      .catch(this.showNetworkError);
  }

  debouncedUpdateElement = debounce(this.updateElement, 750);

  updateNotes(value: any) {
    if (!this.selectedElement) return;
    if (value.html === this.selectedElement.notes) return;
    this.selectedElement.notes = value.html;
    this.debouncedUpdateElement();
  }

  deleteElement(item: ElementListItem): void {
    this.$bvModal
      .msgBoxConfirm(
        this.$t('modals.deleteMaterial.description', {
          name: item.name
        }) as string,
        {
          okVariant: 'danger',
          okTitle: this.t('modals.deleteMaterial.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deleteMaterial.title')
        }
      )
      .then(res => {
        if (!res) return;
        PresentationService.deleteElement(this.form.id, item.id)
          .then(() => {
            if (this.selectedElementIdx >= 0 && this.items[this.selectedElementIdx].id === item.id) this.selectedElementIdx = -1;
            this.items = this.items.filter(i => i.id !== item.id);
            this.$emit('update');
            this.toast(this.$t('modals.deleteMaterial.deleted', {name: item.name}) as string, 'success');
          })
          .catch(this.showNetworkError);
      });
  }

  editElement(item: ElementListItem): void {
    if(item.type === 'SURVEY'){
      (this.$refs['survey-form'] as any).editSurvey(item);
    }
  }

  uploadDocuments(files: File[]): void {
    console.log('Uploading files:', files);
    this.currentUploads = files.map(file => ({
      file,
      progress: 0,
      abortController: new AbortController()
    }));

    console.log('Current uploads:', this.currentUploads);

    const updateProgress = (progressEvent: ProgressEvent, upload: { file: File, progress: number, abortController: AbortController }) => {
      upload.progress = progressEvent.loaded / progressEvent.total * 100;
      console.log(`Upload progress for ${upload.file.name}: ${upload.progress}%`);
    };

    this.currentUploads.forEach(upload => {
      PresentationService.uploadDocuments(this.form.id, upload.file, upload.abortController, (progressEvent) => updateProgress(progressEvent, upload))
        .then(res => {
          this.importProcesses.push({
            id: res,
            presentationId: this.form.id,
            progress: 0,
            status: "WAITING",
            fileName: upload.file.name
          });
          this.getImportProcesses();
          this.toast(this.$t('presentation.document.uploaded') as string, 'success');
        })
        .catch(this.showNetworkError)
        .finally(() => {
          this.currentUploads = this.currentUploads.filter(u => u.file !== upload.file);
        });
    });
  }

  abortUpload(file: File): void {
    const upload = this.currentUploads.find(u => u.file === file);
    if (upload) {
      upload.abortController.abort();
      this.currentUploads = this.currentUploads.filter(u => u.file !== file);
    }
  }

  // abortUpload(): void {
  //   this.abortController.abort();
  //   this.uploadProgress = 0;
  //   this.currentUploadingFile = null;
  //   this.abortController = new AbortController();
  // }

  closeProcess(id: number): void {
    PresentationService.closeImportProcess(this.form.id, id)
      .then(() => this.getImportProcesses())
      .catch(this.showNetworkError);
  }

  selectBetween(item: ElementListItem, value: boolean) {
    const index = this.items.findIndex(i => i.id === item.id);
    const selectedIndex = this.items.findIndex(i => i.selected === value && i.id !== item.id);
    let startIndex: number, endIndex: number;
    if (selectedIndex === -1) {
      startIndex = endIndex = index;
    } else {
      startIndex = Math.min(index, selectedIndex);
      endIndex = Math.max(index, selectedIndex);
    }
    this.items.slice(startIndex, endIndex + 1).forEach(i => {
      if (i.selected !== value) this.rowSelected(i, false);
    });
  }

  updateSelection(value: string | null) {
    if (!value) return;
    switch (value) {
      case 'invert':
        this.items.forEach(item => this.rowSelected(item, false));
        this.allSelected = this.items.every(item => item.selected);
        break;
      case 'all':
        this.allSelected = false;
        this.selectAll();
        break;
      case 'none':
        this.allSelected = true;
        this.selectAll();
        break;
    }
  }

  deleteSelectedIds() {
    this.$bvModal
      .msgBoxConfirm(
        this.$t('modals.deleteMaterials.description', {count: this.editIds.length}) as string,
        {
          okVariant: 'danger',
          okTitle: this.t('modals.deleteMaterials.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deleteMaterials.title')
        }
      )
      .then(res => {
        if (!res) return;
        PresentationService.deleteSelectedElements(this.form.id, this.editIds)
          .then(() => {
            this.editIds = [];
            this.selectedElementIdx = -1;
            this.items = this.items.filter(item => !item.selected);
            this.$emit('update');
            this.toast(this.$t('modals.deleteMaterials.deleted', {count: this.editIds.length}) as string, 'success');
          })
          .catch(this.showNetworkError);
      })
  }

  selectAll(): void {
    this.allSelected = !this.allSelected;
    this.items.forEach(item => {
      if (this.allSelected) {
        if (!item.selected) this.rowSelected(item);
      } else {
        if (item.selected) this.rowSelected(item);
      }
    });
  }

  get editMode(): boolean {
    return this.editIds.length > 0;
  }

  get notesSwitchText(): string {
    return this.$tc('presentation.notesCount', this.items.filter(i => i.notes).length);
  }

  get selectedElement(): ElementListItem | null {
    return this.selectedElementIdx >= 0 ? this.items[this.selectedElementIdx] : null;
  }
}
