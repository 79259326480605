









import {Component, Prop, Vue} from 'vue-property-decorator';
import {ElementListItem} from '@/models/Presentation';

@Component({})
export default class ElementThumbnail extends Vue {
  @Prop({ required: true }) item!: ElementListItem;

  get hasThumbnailUrl(): boolean {
    return !!this.item.thumbnailUrl;
  }

  get isSurvey(): boolean {
    return this.item.type === 'SURVEY' || this.item.type === 'SURVEY_FORM';
  }

}
