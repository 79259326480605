


























import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class FileForm extends Base {

  @Prop({type: String, required: true}) heading!: string;
  @Prop({type: String}) description!: string;
  @Prop({type: String}) accept!: string;
  @Prop({type: Boolean, default: false}) responsive!: boolean;
  @Prop({type: Boolean, default: false}) disabled!: boolean;
  @Prop({type: Number}) fileSizeLimit!: number; // in MB

  files: File[] = [];

  submit() {
    if (!this.files) return;
    if (this.fileSizeLimit) {
      this.files = this.files.filter(file => {

        let fileSizeAllowed = file.size <= this.fileSizeLimit * 1024 * 1024;
        if (!fileSizeAllowed) {
          this.toast(this.$t('common.fileTooLarge', {size: this.fileSizeLimit}) as string, 'danger');
          return false;
        }
        return true;
      });
    }
    this.$emit('files-selected', this.files);
  }

  get colProps() {
    return this.responsive ? { cols: 12, lg: 4, xl: 3 } : {};
  }
}
