























import {Component, Prop, Vue} from 'vue-property-decorator';
import {SurveyElement} from '@/models/Presentation';

@Component
export default class SurveyPreview extends Vue {
  @Prop({ required: true }) surveyElement!: SurveyElement;
}
